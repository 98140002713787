.Page.home{
    .macanudo__blocks__imageblock{
        img{
            @include media("<640px"){
                aspect-ratio: 1;
                object-fit: cover;
                height: auto;
            }
        }
    }
}
