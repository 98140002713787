body{
    @include proximanova;

    &#tinymce{
    }
}

h1, h2, h3, h4, h5, h6{
    @include proximanova(100);
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 0.1em;
    position: relative;
    overflow: hidden;

    font-size:2.25rem;
    font-size:clamp(2.25rem, 7vw, 5rem);

    strong, span.eyebrow{
        font-size: 0.375em;
        @include baskerville();
        line-height: 1;
        display: block;
    }

    a{
        color:currentColor;
        &:hover{
            color:$green;
        }
    }

    &.sub{
        font-size:1.9375rem;
        font-size: clamp(1.9375rem, 4vw, 4rem);
    }

    &.sm{
        font-size:1.9375rem;
        font-size: clamp(1.9375rem, 4vw, 3.375rem);
    }

    &.bask{
        @include baskerville(700);
        font-size: 1.25rem;
        font-size: clamp(1.25rem, 5vw, 2.5rem);
    }
    & > span{
        position: relative;
        &.split-parent{
            overflow: hidden;
        }
        &.split-child{
            display: inline-block;
        }
    }

    & + p, & + ul{
        margin-top:1.25em;
    }
}



p, ul, li{
    @include proximanova(400);
    font-size: 0.9375rem;
    font-size: clamp(0.9375rem, 2.5vw, 1.5rem);
    margin-bottom:1em;

    strong{
        @include baskerville;
    }

    a{
        color:currentColor;
        &:hover{
            color:$green;
        }
    }
}
