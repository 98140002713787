footer.main{
    z-index: 2;
    padding: 2em;
    text-align: center;
    border-top:2px solid $border;

    p{
        margin-bottom:0;
        font-size: 1rem;
    }

    nav{
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        @include media(">=800px"){
            justify-content: flex-end;
        }

        a{
            color:black;
            text-decoration: none;
            &:hover{
                color:$green;
            }
        }

        ul {
            list-style: none;
            margin:1em 0;
            padding:0 1em;
            font-size: 1rem;

            @include media(">=800px"){
                margin:0;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                li{
                    margin:0 1em;
                    font-size: 1rem;
                }
            }

            li.social{
                display: flex;
                align-items: center;
            }

            svg{
                fill:currentColor;
                width: auto !important;
                height: 26px !important;
                margin:1em;
            }
        }
    }

    @include media(">=800px"){
        display: flex;
        align-items: center;
    }
}
