dialog{
    padding: 0;
    position: fixed;
    width: 100%;
    max-width: 100%;
    margin:0;
    border: 0;
    top:0;
    left:0;
    background-size:cover;
    min-height:100vh;
    overflow-y:auto;
    overflow-x:hidden;


    .container{
        width: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0;
    }

    p{
        text-transform: uppercase;
        text-align: center;
        max-width: 700px;
        margin: 2em auto 0;

        a{
            text-decoration: underline;
        }

        &.legal{
            font-size: .85em;
            text-transform: none;
            font-weight: 400;
        }
    }
    .Actions{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 0 3rem;
        width: 75%;
        max-width: 600px;
        button{
            width:45%;
        }
    }

    .close{
        text-decoration: none;
        position: absolute;
        top:2em;
        right:2em;
        line-height:1;
        border-radius:50%;
        width:2.5em;
        height:2.5em;
        justify-content: center;
        letter-spacing: 0;
        line-height: 1;
    }
}
