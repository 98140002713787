.Macanudo\\Pages\\RegistrationPage, .Macanudo\\Pages\\ContactPage{
    .app .container{
        max-width: 970px;
        padding:4em 0;

        form{
            .inputs{
                display:flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .field-wrap{
                    width:100%;
                    textarea{
                        margin-bottom:2em;
                    }
                    @include media(">=tablet"){
                        width:48%;

                        &.Zip{
                            width:30%;
                        }
                        &.SurveyOptIn, &.Message{
                            width:100%;
                        }
                    }
                }
            }

            .actions{
                text-align: center;
            }
        }
    }

    &.thanks{
        text-align: center;

        svg{
            display: block;
            width:50%;
            max-width: 150px;
            height:auto;
            margin:2em auto;
            .cigar-world-logo-mark-2{
                fill:$orange;
            }

        }
    }
}
