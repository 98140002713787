@use "sass:math";

@mixin aspect-ratio($width, $height) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (math.div($height, $width)) * 100%;
    }
}
/* For image replacement */
body.mceContentBody{font-size:13px;}
.ir { display: inline-block; text-indent: -999em; overflow: hidden; background-repeat: no-repeat; text-align: left; direction: ltr; }
.ir br { display: none; }

/* Hide for both screenreaders and browsers:
css-discuss.incutio.com/wiki/Screenreader_Visibility */
.hidden { display: none; visibility: hidden; }

/* Hide only visually, but have it available for screenreaders: by Jon Neal.
www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden */
.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -2px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    font-family:"Gotham A", "Gotham B", 'Lato';
    font-style: normal;
    font-weight: 700;
    letter-spacing: ls(100);
    text-transform: uppercase;
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638 */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: absolute;
    width: auto;
    background-color: white;
    padding: .25em .5em;
    color: black;
    font-size: .8em;
    font-family:"Gotham A", "Gotham B", 'Lato';
    font-style: normal;
    font-weight: 700;
    letter-spacing: ls(100);
    text-transform: uppercase;
    margin-top: 5px;
    outline:0;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible { visibility: hidden; }

/* Contain floats: nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }
.clear{clear:both;}
.left{float:left;}
.right{float:right;}
.center, .text-center{
    text-align: center;
}
.text-left {
    text-align: left
}
.text-center {
    text-align: center
}
.text-right {
    text-align: right
}
.no-pad-top{
    padding-top:0 !important;
}
.no-pad-bottom{
    padding-bottom:0 !important;
}
.no-pad{
    padding-top:0 !important;
    padding-bottom:0 !important;
}
.larger{
    @include media(">=640px"){
        font-size:1.5rem;
    }
}

img{
    &.left {
        max-width: 50%;
        margin: 0.5em 2em 1em 0;
    }
    &.right {
        max-width: 50%; /* Responsive width */
        margin: 0.5em 0 1em 2em;
    }
    &.leftAlone {
        float: left;
        margin-right: 100%;
        margin-bottom: 1em;
        clear: both;
    }
    &.center {
        float: none;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-bottom: 1em;
        clear: both;
    }
}

.captionImage {
    width: 100%;
    margin-top: 0.5em;

    img {
        margin: 0;
        float: none;
        max-width: none;
        width: 100%;
    }
    p {
        clear: both;
        margin: 0.5em 0;
        font-style: italic;
    }
    &.left{
        margin: 0.5em 3em 2em 0;
        max-width: 50%;
        img{
            margin-right: -1em;
        }
    }
    &.right{
        margin: 0.5em 0 2em 3em;
        max-width: 50%;
        img{
            margin-left: -1em;
        }
        p{
            margin-left: -1em;
            text-align: left;
        }
    }
    &.leftAlone{
        float:none;
        margin: 0 2em 2em 0;
    }
    &.center{
        margin: 0 auto 2em;
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
