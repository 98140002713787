.userform,
form {
    margin:1em auto;
    width:90%;
    max-width: 800px;
    .field {
        margin: 0 0 .5em;
    }

    .Actions {
        margin: 1em 0;
    }

    .left {
        float: none;
    }

    .btn-toolbar {
        text-align: center;

        p {
            margin: 1em 0;
        }
    }
}

input[type=number] {
    -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    @include proximanova(700);
    text-transform: uppercase;
    color: $border;
}

::-moz-placeholder {
    /* Firefox 19+ */
    @include proximanova(700);
    text-transform: uppercase;
    color: $border;
}

:-ms-input-placeholder {
    /* IE 10+ */
    @include proximanova(700);
    text-transform: uppercase;
    color: $border;
}

:-moz-placeholder {
    /* Firefox 18- */
    @include proximanova(700);
    text-transform: uppercase;
    color: $border;
}

.narrow {
    width: 400px !important;
    margin: 0 auto;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="file"],
select,
textarea {
    background: none;
    text-align: left;
    font-size: 1.03em;
    padding: 0.7em;
    width: 100%;
    letter-spacing: 0;
    border-radius: 0;
    box-sizing: border-box;
    border: 2px solid $border;
    text-transform: none;

    &.error {
        border-color: red;
    }

    &:focus {
        outline: 0;
    }
}


textarea {
    height: 8em;
}

.custom-select {
    padding: 0 1.5em 0 0;
    margin: 0;
    border: 2px solid $border;
    width: 100%;
    overflow: hidden;
    background: url("/images/chevron-down.svg") no-repeat 95% 50%;
    background-position-x: calc(100% - 0.7em);
    background-size: 0.7rem;
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;

    text-transform: none;


    label.error {
        position: absolute;
    }

    select {
        width: calc(100% + 1.5em);
        height: 100%;
        box-sizing: border-box;
        top: 0;
        left:0;
        position: relative;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            outline: 0;
        }
    }
}

label {
    font-size: 1rem;
    float: none !important;
    margin-bottom: 1rem;
    line-height: 1rem;
    display: block;
    @include proximanova(700);

    &:not(.error, .checkbox, .radio){
        @include proximanova(700);
        text-transform:uppercase;
        color:$border;
    }

}

fieldset {
    border: none;
    padding: 0;
    margin: 0;
}

label.error,
span.error {
    color: #f00;
    font-style: italic;
    font-size: 0.8em;
    display: block !important;
    margin: 0.25em 0 0.5em;
    text-transform: none;
}

label.checkbox,
label.radio {
    cursor: pointer;
    padding-left: 2.5em;
    display: inline-block;
    position: relative;
    width: auto;
    text-align: left;
    line-height: 1.5em;
    letter-spacing: 0;
    margin:1em 0;

    a {
        color:currentColor;
        text-decoration: underline;
        &:hover{
            color:$green;
        }
    }

    span {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.5em;
        height: 1.5em;
        line-height: 1.5em;
        border: 2px solid $border;
        border-radius: 0;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
            position: absolute;
            top: 12.5%;
            left: 12.5%;
            width: 75%;
            height: 75%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-family: sans-serif;
            font-weight: bold;
            display: none;
            content: url("/images/close-icon.svg");
            color: black;
            line-height: 1em;
        }
    }

    input {
        position: absolute;
        z-index: -1;
        opacity: 0;

        &:disabled ~ span {
            pointer-events: none;
            opacity: 0.6;
            background: #ccc;
        }

        &:checked ~ span {
            background: white;
            color: black;
        }

        &:checked ~ span:after {
            display: block;
            color: black;
        }
    }

    &:hover {
        input ~ span {
            background: #ccc;
        }

        input:not([disabled]):checked ~ span {
            background: #ccc;
            color: black;
        }
    }
    @include media("<640px") {
        font-size: 1em;
    }
}

label.checkbox {
    @include media('<=640px') {
        margin-bottom: 0;
    }
}

label.radio {
    span {
        border-radius: 50%;
        overflow: hidden;


        &:after {
            content: '';
            background: black;
            width: 0.88em;
            height: 0.88em;
            position: relative;
            top: 0;
            left: 0;
            border-radius: 50%;
        }
    }

    input {
        &:disabled ~ span {
            pointer-events: none;
            opacity: 0.6;
        }

        &:checked ~ span {
            background: white;
            color: black;
        }
    }

    &:hover {
        input ~ span {
            background: white;

            &:after {
                color: black;
            }
        }

        input:not([disabled]):checked ~ span {
            background: #ccc;
            color: black;
        }
    }
}

.btn-toolbar.Actions, .actions p {
    text-align: center;
}

.actions p{
    margin-top:1em;
}


.honeypot{
    display:none !important;
}

.field{
    position: relative;
    margin-bottom:1.1rem;

    &.text, &.email, &.password, &.textarea, &.dropdown, &.phonenumber{
        .middleColumn, .custom-select{
            display: flex;
            flex-direction: column-reverse;
        }
        label:not(.error){
            transform:translate(1em, 3em);
            transition: all 0.3s;
        }
        .custom-select {
            margin-top:2em;
            select{
                position: relative;
                z-index: 1;
            }
            label:not(.error){
                transform:translate(1.1em, 0);
                position: absolute;
            }
        }
        .custom-select ~ label{
            display: none;
        }
        input:focus ~ label, input:-webkit-autofill ~ label, textarea:focus ~ label, textarea:-webkit-autofill ~ label, input:not([value=""]):valid ~ label, textarea:not([value=""]):valid ~ label{
            transform:none;
        }
        select:focus ~ label, select:-webkit-autofill ~ label, .custom-select select:not([value=""]):valid ~ label{
            transform: translate(0, -3.1em) !important;
        }

    }
}

