body{
    overflow-x: hidden;
    max-width: 100%;
    display:flex;
    flex-direction: column;
    align-items: stretch;
    min-height:100dvh;
    height: 100dvh;
    background:white;
    padding-top:75px;
    color:$gray;

    &.is-transitioning {
        pointer-events: none;
        cursor: progress;
    }

    header.main{
        order:0;
    }
    main{
        order:1;
        flex-grow: 1;
    }
    footer.main{
        order:2;
    }

    @include media(">=960px"){
        padding-top:200px;
    }
}

.container{
    max-width: 1240px;
    width:85%;
    margin: 0 auto;
    position: relative;
}

.glide__slides{
    overflow:visible;
}

@mixin flex{
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin flex-c{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hero{
    width:100%;
    display: block;
    img,video{
        width:100%;
        height:auto;
        display: block;
    }

    &.covered{
        position: relative;
        height:62svh;
        height:62vh;
        max-height:400px;
        object-fit: cover;
        object-position: center top;

        @include media(">=tablet"){
            height: 718px;
            max-height: 100vh;
        }
    }

    @include media(">=tablet"){
        min-height:1vh;
    }
}

.introcopy{
    padding:2em 0 4em;
    width:81.25%;
    max-width: 870px;
    margin:0 auto;
    text-align: center;

    p{
        margin-bottom:0;
        margin-top:1em;
    }

    &.square{
        padding:3em 0;
        @include media(">=tablet"){
            padding:6em 0;
        }
    }

    @include media(">=tablet"){
        padding:6em 0 8em;
    }
}


.split-content{
    margin:4em 0;
    border-bottom:1px solid $border;

    .container{
        max-width: 1380px;
        text-align: center;
        width:100%;

        @include media(">=tablet"){
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }

    img, .video-container, video, a.image{
        width:100%;
        max-width: 685px;
        height: auto;
        margin:0 auto;

        video, iframe{
            width:100%;
            height:auto;
            aspect-ratio: 16/9;
        }
        img{
            width:100%;
            display: block;
        }
        @include media(">=tablet"){
            width:50%;
            margin:0;
        }
    }
    .copy{
        padding:2em 0;
        width:85%;
        margin:0 auto;
        p{
            font-size: clamp(0.8125rem, 2vw, 1.25rem);
        }

        @include media(">=tablet"){
            margin:0;
            text-align: left;
            padding:0 0 0 12%;
            width:46%;
        }
    }

    @include media(">=tablet"){
        margin:6em 0 0;
        border-top:1px solid $border;
    }
}

.announcement{
    padding:4em 0;
    border-bottom:1px solid $border;
    position: relative;

    img{
        width:100%;
        height:auto;
        display: block;

        @include media(">=tablet"){
            aspect-ratio: 1514/651;
            object-fit: cover;
        }
    }

    .copy{
        width:82%;
        margin:0 auto;
        padding-top:2em;
        text-align: center;

        @include media(">=tablet"){
            position: absolute;
            margin: 0;
            padding:0;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            text-align: left;
            .wrap{
                width:90%;
                max-width: 1280px;
                margin:0 auto;

                .innerwrap{
                    width:100%;
                    max-width: 580px;
                }
            }

            h3, p{
                text-shadow: 5px 5px 20px #000000;
            }
        }
    }

    @include media(">=tablet"){
        padding:8em 0;
        border-bottom:0;
        aspect-ratio: 1514/651;
    }

    .Macanudo\\Pages\\ProductsPage &{
        padding-top:0 !important;
    }
}
