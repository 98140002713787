.Macanudo\\Pages\\AboutPage{

    .features{
        text-align: center;

        .feature{
            border-top:1px solid $border;

            img{
                width:66.875%;
                height:auto;
                margin-top:-2em;
                max-width: 534px;
                @include media(">=tablet"){
                    order:1;
                    width:43%;
                    position: absolute;
                    right:0;
                    top:50%;
                    transform:translateY(-50%);
                }
            }

            .copy{
                padding:2em 0 4em;
                width:80%;
                max-width: 520px;
                margin:0 auto;

                h2{
                   font-size: clamp(1.25rem, 3vw, 2rem)
                }

                @include media(">=tablet"){
                    margin: 0;
                    padding:6em 0 6em 12%;
                    border-left:1px solid;
                    order:0;
                    width:54%;
                }
            }

            .wrap{
                @include media(">=tablet"){
                    width:90%;
                    max-width: 1234px;
                    margin:0 auto;
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    position: relative;
                    text-align: left;
                }
            }

            &:last-child{
                border-bottom:1px solid $border;

                @include media(">=tablet"){
                    margin-bottom:8em;
                    .wrap{
                        justify-content: flex-end;
                    }

                    img{
                        left:0;
                        top:55%;
                    }

                    .copy{
                        max-width: 580px;
                        padding-top:4em;
                        padding-bottom:4em;
                    }
                }
            }

            @include media(">=tablet"){
                border-top:1px solid $border;
                border-bottom:1px solid $border;
                margin:8em 0 16em;
            }
        }
    }
}

.Macanudo\\Pages\\ExperiencePage{
    .introcopy{
        max-width: 1355px;

        p{
            max-width: 698px;
            margin:1em auto 0;
        }
    }
}
