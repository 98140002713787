header{
    height: 75px;
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    z-index:10;
    background:white;
    display: flex;
    transition: box-shadow 0.3s;
    border-bottom:2px solid $border;

    &.scrolled{
        box-shadow: rgba(0,0,0, 0.16) 0px 3px 6px, rgba(0,0,0, 0.23) 0px 3px 6px;
    }


    nav{
        padding:1rem;
        width:100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(">=960px"){
            max-width: 1440px;
            margin:0 auto;
        }
    }

    .logo{
        flex-grow: 1;
        img{
            width:70px;
            height:auto;
            display: block;

            @include media(">=960px"){
                width:100%;
            }
        }
        @include media(">=960px"){
            width:15%;
            max-width: 212px;
            flex-grow: initial;
        }
    }

    ul{
        list-style: none;
        padding: 0;
        margin:0;
        text-align: center;
        line-height: 1;
        font-size: 1rem;
        position: absolute;
        top:75px;
        width:100%;
        left:100%;
        transition: transform 0.3s;
        min-height:calc(100vh - 75px);
        background:white;
        padding-top:2em;
        @include media(">=960px"){
            display: flex;
            justify-content: space-evenly;
            padding:1rem;
            border-top:1px solid $gold;
            border-bottom:1px solid $gold;
            position: relative;
            transform:none;
            left:0;
            top:0;
            min-height: 1px;
            width: calc(85% - 2rem);
            margin-left: 2rem;
        }
        li{
            line-height: 1;
            font-size: 1rem;
            @include media(">=960px"){
                margin-bottom: 0;
                font-size: 0.8rem;
            }

            @include media(">=1100px"){
                font-size: 1rem;
            }
        }

        a{
            color:$gray;
            @include proximanova(600);
            letter-spacing:0.2em;
            text-transform: uppercase;
            text-decoration: none;
            line-height: 1;
        }
    }

    nav.active{
        ul.secondary{
            transform:translateX(-100%);
        }
    }

    .hamburger{
        width: auto;
        min-width:1em;
        display: flex;

        z-index: 1;
        .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after{
            background:$gold;
            height: 2px;
        }

        @include media(">=960px"){
            display: none;
        }
    }

    @include media(">=960px"){
        height:200px;
    }
}
