button, a{
    padding:none;
    display: inline-block;
    text-decoration: underline;
    background:none;
    border:none;
    cursor:pointer;
}

.btn{
    border:1px solid;
    color:black;
    display: inline-block;
    line-height: 0;
    text-align: center;
    overflow:hidden;
    cursor:pointer;
    position: relative;
    transition: background 0.3s;
    .ir{
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
    }

    button, a{
        color:black;
        text-decoration: none;
        padding:0.875em;
        min-width:7.375em;
        line-height:1;
        overflow:hidden;
        @include proximanova(400);
        letter-spacing: 0.01em;
        text-transform: uppercase;
    }

    &:hover{
        background:$green;
    }
}
input[type="submit"]{
    background: none;
    border:1px solid;
    color:black;
    display: inline-block;
    line-height: 1;
    text-align: center;
    overflow:hidden;
    cursor:pointer;
    @include proximanova(400);
    letter-spacing: 0.01em;
    padding:0.875em;
    min-width:7.375em;
    text-decoration: none;
    text-transform: uppercase;
    transition: background 0.3s;
    &:hover{
        background:$green;
    }
}

.btn.inline{
    color:black;
    text-decoration: none;
    padding:0.875em;
    min-width:7.375em;
    line-height:1;
    overflow:hidden;
    @include proximanova(400);
    text-transform: uppercase;
    min-height:2.75em;
}

.logolink{
    width:100%;
}
