.element.macanudo__blocks__tabularcontentblock.noborders{
    padding-top: 0px !important;
    padding:0px !important;
}

.tabular-content{
    display: grid;
    grid-template-columns: repeat(3, 250px [col-start]);
    grid-template-rows:[row1-start] 60% [row1-end row2-start] 60% [row2-end];
    justify-content: center;

    .section{
        padding: 0 10px;
        h2{
            text-align: center;
            padding: 0 0 5px;
            &.bask{
                font-size: 1.15em !important;
            }
        }
        hr{
            width:40%;
            margin:0 auto 20px;
            color: black !important;
        }
        p{
            padding: 0px 0 0;
            font-size: 0.9375rem !important;
        }
        img{
            width: 30px;
            height: auto;
        }
        @include media('<tablet'){
            margin-bottom: 50px;
        }
    }
    @include media('<tablet'){
        display:block !important;
    }
}