.macanudo__blocks__imageblock{
  .image-container{
    width:100%;
    margin: 0 auto;

    img{
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}
