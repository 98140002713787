.element{
    padding:4em 0;
    scroll-margin-top: calc(80px - 2em);


    &:not(.noborders){
        border-top:1px solid $border;
    }
    .element-title{
        padding:0;
        margin-bottom:0.25em;
    }

    @include media(">=tablet"){
        padding:6em 0;
    }
    @include media(">=960px"){
        scroll-margin-top: calc(200px - 4em);
    }
}

.element + .element.macanudo__blocks__promotionblock, .element.macanudo__blocks__promotionblock + .element, .element.macanudo__blocks__splitcontentblock + .element, .element + .macanudo__blocks__splitcontentblock {
    border-top: 0;
    padding-top:0;
}

.element.dnadesign__elemental__models__elementcontent.noborders{
    padding: 6em 0 3em;
}

.no-pad{
    padding: 0 !important;
}

.no-pad-top{
    padding-top: 0 !important;
}

.no-pad-bottom{
    padding-bottom: 0 !important;
}

.element.macanudo__blocks__imageblock {
    &:first-child{
        padding: 0px !important;
    }
}

@import 'carousel';
@import 'image';
@import 'sidebyside';
@import 'video';
@import 'promotion';
@import 'tab';
