.macanudo__blocks__videoblock, .macanudo__blocks__embeddedvideoblock{
    padding: 0px !important;
    .video-container{
        margin: 0 auto;
        position:relative;
        width: 100%;
        @include aspect-ratio(16,9);
        video, iframe, embed, object, .plyr{
            width: 100%;
            height: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    
    video{
        width:100%;
    }
}



.embed{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    @include aspect-ratio(16,9);

    iframe, video, img{
        width: 100%;
        height: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.macanudo__blocks__embeddedvideoblock{
    .video-container{
        @include aspect-ratio(16,9);
    }
}
.plyr{
    --plyr-color-main:$green;

    &.plyr--stopped{
        .plyr__controls{
            display: none;
        }
    }

    .plyr__control--overlaid{
        background:none;
        border:3px solid white;
        padding:2em;
        box-shadow: 0px 0px 14px 0px rgba(21, 21, 49, 0.66);

        svg{
            width: 2em;
            height: 2em;
        }

        &:hover{
            background:$green;
        }
    }
    &.plyr--full-ui input[type='range']{
        color:$green;
    }

    &.plyr--video {
        .plyr__control.plyr__tab-focus, .plyr__control:hover, .plyr__control[aria-expanded='true']{
            background:$green;
        }
    }

    .plyr__poster{
        background-size:cover;
    }
}
