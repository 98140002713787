.private-lockers{
    .content{
        h1{
            font-size: 4em !important;
        }
        hr{
            margin-left: auto !important;
            margin-right: auto !important;
            @include media('>tablet'){
                margin-left:0px !important;
            }
        }
        .btn{
            width: 265px;
            height: auto;
        }
    }
}