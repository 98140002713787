#agegate-dialog{
	display: flex;

    &:not([open]){
        display: none !important;
    }

	.container{
		min-height: 100vh;
		margin: auto;
        position: relative;
        width:100%;
        border-bottom:2px solid $border;
        max-width: 1300px;
        justify-content: flex-end;

        @include media(">tablet"){
            padding:75px 75px 0;
            width:95%;
            @media screen and (min-height:800px){
                min-height:800px;
            }

        }


	}

	// .man{
    //     width:auto;
    //     height:80vh;
    //     @media screen and (orientation:landscape) and(max-width:768px){
    //         width:95%;
    //         height:auto;
    //     }

    //     @include media(">=tablet"){
    //         width:95%;
    //         height:auto;
    //     }
    // }

	.Actions{
		width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn{
            width:45%;
            button{
                width:100%;
                min-width: 100%;
            }
        }

	}
	form{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 640px;
        text-align: center;
	}
}
