.Macanudo\\Pages\\EventsPage{
    .actions {
        text-align: center;
        padding-bottom: 2em;
    }
    .btn{
        box-sizing: border-box;
        width: 200px;
        margin: 0px 20px;
    }

    .container{
        justify-content:center;
        margin: 0 auto;
    }
    .event img{
        max-width: 300px;
        padding: 50px 0;
        @include media('<tablet'){
            max-width: 200px;
            padding: 20px 0;
        }
    }

    .image-container{
        width:100%;
        margin: 0 auto;
    
        img{
          width: 100%;
          max-width: 100%;
          height: auto;
        }
      }

    .copy{
        h3.location.bask{
            font-size: 1em;
        }

        hr{
            max-width: 150px;
            margin-bottom: 40px;
            @include media('>tablet'){
                margin-left:0px;
            }
        }

        h2{
            font-size: 2em;
        }

        p{
            font-size: clamp(0.9375rem, 2.5vw, 1.5rem);
            margin-top: 10px;
        }
    }
}