@charset "UTF-8";
/*!
 * Bootstrap Reboot v4.0.0 (https://getbootstrap.com)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * Forked from Normalize.css, licensed MIT (https://github.com/necolas/normalize.css/blob/master/LICENSE.md)
 */
@import '~dialog-polyfill/dialog-polyfill.css';
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
  width: device-width;
}
article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-block-start: 0;
  margin-block-end: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* For image replacement */
body.mceContentBody {
  font-size: 13px;
}

.ir {
  display: inline-block;
  text-indent: -999em;
  overflow: hidden;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
}

.ir br {
  display: none;
}

/* Hide for both screenreaders and browsers:
css-discuss.incutio.com/wiki/Screenreader_Visibility */
.hidden {
  display: none;
  visibility: hidden;
}

/* Hide only visually, but have it available for screenreaders: by Jon Neal.
www.webaim.org/techniques/css/invisiblecontent/  &  j.mp/visuallyhidden */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  font-family: "Gotham A", "Gotham B", "Lato";
  font-style: normal;
  font-weight: 700;
  letter-spacing: ls(100);
  text-transform: uppercase;
}

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: drupal.org/node/897638 */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: absolute;
  width: auto;
  background-color: white;
  padding: 0.25em 0.5em;
  color: black;
  font-size: 0.8em;
  font-family: "Gotham A", "Gotham B", "Lato";
  font-style: normal;
  font-weight: 700;
  letter-spacing: ls(100);
  text-transform: uppercase;
  margin-top: 5px;
  outline: 0;
}

/* Hide visually and from screenreaders, but maintain layout */
.invisible {
  visibility: hidden;
}

/* Contain floats: nicolasgallagher.com/micro-clearfix-hack/ */
.clearfix:before, .clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
}

.clear {
  clear: both;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center, .text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.no-pad-top {
  padding-top: 0 !important;
}

.no-pad-bottom {
  padding-bottom: 0 !important;
}

.no-pad {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

@media (min-width: 640px) {
  .larger {
    font-size: 1.5rem;
  }
}

img.left {
  max-width: 50%;
  margin: 0.5em 2em 1em 0;
}
img.right {
  max-width: 50%; /* Responsive width */
  margin: 0.5em 0 1em 2em;
}
img.leftAlone {
  float: left;
  margin-right: 100%;
  margin-bottom: 1em;
  clear: both;
}
img.center {
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 1em;
  clear: both;
}

.captionImage {
  width: 100%;
  margin-top: 0.5em;
}
.captionImage img {
  margin: 0;
  float: none;
  max-width: none;
  width: 100%;
}
.captionImage p {
  clear: both;
  margin: 0.5em 0;
  font-style: italic;
}
.captionImage.left {
  margin: 0.5em 3em 2em 0;
  max-width: 50%;
}
.captionImage.left img {
  margin-right: -1em;
}
.captionImage.right {
  margin: 0.5em 0 2em 3em;
  max-width: 50%;
}
.captionImage.right img {
  margin-left: -1em;
}
.captionImage.right p {
  margin-left: -1em;
  text-align: left;
}
.captionImage.leftAlone {
  float: none;
  margin: 0 2em 2em 0;
}
.captionImage.center {
  margin: 0 auto 2em;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

@keyframes plyr-progress {
  to {
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}
.plyr video,
.plyr audio,
.plyr iframe {
  display: block;
  height: 100%;
  width: 100%;
}
.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}
.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}
.plyr--full-ui *,
.plyr--full-ui *::after,
.plyr--full-ui *::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: var(--plyr-badge-background, hsl(216deg, 15%, 34%));
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}
.plyr__captions span:empty {
  display: none;
}
@media (min-width: 480px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}
@media (min-width: 768px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: var(--plyr-captions-background, rgba(0, 0, 0, 0.8));
  border-radius: 2px;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}
.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: var(--plyr-control-radius, 3px);
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
  position: relative;
  transition: all 0.3s ease;
}
.plyr__control svg {
  display: block;
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
}
.plyr__control:focus {
  outline: 0;
}
.plyr__control.plyr__tab-focus {
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%)))) dotted 3px;
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}
a.plyr__control::after, a.plyr__control::before {
  display: none;
}

.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}
.plyr__controls .plyr__controls__item {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}
.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}
.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}
.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}
.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
  padding-left: 0;
}
.plyr__controls:empty {
  display: none;
}

.plyr [data-plyr=captions],
.plyr [data-plyr=pip],
.plyr [data-plyr=airplay],
.plyr [data-plyr=fullscreen] {
  display: none;
}

.plyr--captions-enabled [data-plyr=captions],
.plyr--pip-supported [data-plyr=pip],
.plyr--airplay-supported [data-plyr=airplay],
.plyr--fullscreen-enabled [data-plyr=fullscreen] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}
.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}
.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}
.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}
.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  border-radius: 4px;
  bottom: 100%;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: var(--plyr-menu-color, hsl(216deg, 15%, 34%));
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
.plyr__menu__container::after {
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  content: "";
  height: 0;
  position: absolute;
  right: calc(((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7)) - (var(--plyr-menu-arrow-size, 4px) / 2));
  top: 100%;
  width: 0;
}
.plyr__menu__container [role=menu] {
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
}
.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}
.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}
.plyr__menu__container .plyr__control {
  align-items: center;
  color: var(--plyr-menu-color, hsl(216deg, 15%, 34%));
  display: flex;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 1.5) calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5);
  user-select: none;
  width: 100%;
}
.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}
.plyr__menu__container .plyr__control::after {
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
}
.plyr__menu__container .plyr__control--forward::after {
  border-left-color: var(--plyr-menu-arrow-color, hsl(216deg, 15%, 52%));
  right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
}
.plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}
.plyr__menu__container .plyr__control--back {
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(var(--plyr-control-spacing, 10px) * 0.7);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
  position: relative;
  width: calc(100% - (calc(var(--plyr-control-spacing, 10px) * 0.7) * 2));
}
.plyr__menu__container .plyr__control--back::after {
  border-right-color: var(--plyr-menu-arrow-color, hsl(216deg, 15%, 52%));
  left: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5) - var(--plyr-menu-item-arrow-size, 4px));
}
.plyr__menu__container .plyr__control--back::before {
  background: var(--plyr-menu-back-border-color, hsl(216deg, 15%, 88%));
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  left: 0;
  margin-top: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}
.plyr__menu__container .plyr__control--back.plyr__tab-focus::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}
.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7);
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before, .plyr__menu__container .plyr__control[role=menuitemradio]::after {
  border-radius: 100%;
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all 0.3s ease;
  width: 16px;
}
.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(35, 40, 47, 0.1);
}
.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) - 2) * -1);
  overflow: hidden;
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 3.5);
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  display: block;
  height: calc((var(--plyr-range-thumb-active-shadow-width, 3px) * 2) + var(--plyr-range-thumb-height, 13px));
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}
.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%));
}
.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
  appearance: none;
  margin-top: calc((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px)) / 2 * -1);
}
.plyr--full-ui input[type=range]::-moz-range-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
}
.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
}
.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
}
.plyr--full-ui input[type=range]::-ms-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  color: transparent;
}
.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
}
.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  background: currentColor;
}
.plyr--full-ui input[type=range]::-ms-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
  margin-top: 0;
}
.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}
.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}
.plyr--full-ui input[type=range]:focus {
  outline: 0;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%)))) dotted 3px;
  outline-offset: 2px;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%)))) dotted 3px;
  outline-offset: 2px;
}
.plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  outline: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%)))) dotted 3px;
  outline-offset: 2px;
}

.plyr__poster {
  background-color: var(--plyr-video-background, var(--plyr-video-background, rgb(0, 0, 0)));
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time::before {
  content: "⁄";
  margin-right: var(--plyr-control-spacing, 10px);
}
@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

/* stylelint-disable selector-max-compound-selectors */
.plyr__tooltip {
  background: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: var(--plyr-tooltip-radius, 5px);
  bottom: 100%;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: var(--plyr-tooltip-color, hsl(216deg, 15%, 34%));
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(calc(var(--plyr-control-spacing, 10px) / 2) * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}
.plyr__tooltip::before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}
.plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7));
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}
.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: calc((var(--plyr-control-icon-size, 18px) / 2) + calc(var(--plyr-control-spacing, 10px) * 0.7));
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1);
}

.plyr__progress {
  left: calc(var(--plyr-range-thumb-height, 13px) * 0.5);
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}
.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}
.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}
.plyr__progress .plyr__tooltip {
  left: 0;
  max-width: 120px;
  overflow-wrap: break-word;
  white-space: normal;
}

.plyr__progress__buffer {
  -webkit-appearance: none; /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%;
}
.plyr__progress__buffer::-webkit-progress-bar {
  background: transparent;
}
.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: var(--plyr-range-track-height, 5px);
  transition: width 0.2s ease;
}
.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: var(--plyr-range-track-height, 5px);
  transition: width 0.2s ease;
}
.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}

.plyr__progress__marker {
  background-color: var(--plyr-progress-marker-background, #fff);
  border-radius: 1px;
  height: var(--plyr-range-track-height, 5px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
}

.plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}
.plyr__volume input[type=range] {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  position: relative;
  z-index: 2;
}

.plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, hsl(216deg, 15%, 34%));
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}

.plyr--video {
  background: var(--plyr-video-background, var(--plyr-video-background, rgb(0, 0, 0)));
  overflow: hidden;
}
.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: var(--plyr-video-background, var(--plyr-video-background, rgb(0, 0, 0)));
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed,
  .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}
@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control.plyr__tab-focus, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198deg, 100%, 50%))));
  border: 0;
  border-radius: 100%;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}
.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}
.plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
.plyr:fullscreen video {
  height: 100%;
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}
.plyr--fullscreen-fallback video {
  height: 100%;
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}
.plyr__ads::after {
  background: hsl(216deg, 15%, 16%);
  border-radius: 2px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
}
.plyr__ads:empty::after {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: var(--plyr-tooltip-radius, 5px);
  bottom: 100%;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}
.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
.plyr__preview-thumb::before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: calc(50% + var(--preview-arrow-offset));
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}
.plyr__preview-thumb__image-container {
  background: hsl(216deg, 15%, 79%);
  border-radius: calc(var(--plyr-tooltip-radius, 5px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container::after {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.plyr__preview-thumb__image-container::after {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  content: "";
  pointer-events: none;
}
.plyr__preview-thumb__image-container img {
  max-height: none;
  max-width: none;
}
.plyr__preview-thumb__time-container {
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: calc(var(--plyr-tooltip-radius, 5px) - 1px);
  border-bottom-right-radius: calc(var(--plyr-tooltip-radius, 5px) - 1px);
  bottom: 0;
  left: 0;
  line-height: 1.1;
  padding: 20px 6px 6px;
  position: absolute;
  right: 0;
  z-index: 3;
}
.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}
.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}
.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:focus {
  outline: 0;
}
.hamburger:hover {
  filter: opacity(50%);
}
.hamburger.is-active:hover {
  filter: opacity(50%);
}
.hamburger-box {
  width: 28px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 28px;
  height: 3px;
  background-color: #837754;
  border-radius: 5px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -8px;
}
.hamburger-inner::after {
  bottom: -8px;
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

body {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.2;
  letter-spacing: 0.1em;
  position: relative;
  overflow: hidden;
  font-size: 2.25rem;
  font-size: clamp(2.25rem, 7vw, 5rem);
}
h1 strong, h1 span.eyebrow, h2 strong, h2 span.eyebrow, h3 strong, h3 span.eyebrow, h4 strong, h4 span.eyebrow, h5 strong, h5 span.eyebrow, h6 strong, h6 span.eyebrow {
  font-size: 0.375em;
  font-family: baskerville-urw, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1;
  display: block;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: currentColor;
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
  color: #C6D552;
}
h1.sub, h2.sub, h3.sub, h4.sub, h5.sub, h6.sub {
  font-size: 1.9375rem;
  font-size: clamp(1.9375rem, 4vw, 4rem);
}
h1.sm, h2.sm, h3.sm, h4.sm, h5.sm, h6.sm {
  font-size: 1.9375rem;
  font-size: clamp(1.9375rem, 4vw, 3.375rem);
}
h1.bask, h2.bask, h3.bask, h4.bask, h5.bask, h6.bask {
  font-family: baskerville-urw, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  font-size: 1.25rem;
  font-size: clamp(1.25rem, 5vw, 2.5rem);
}
h1 > span, h2 > span, h3 > span, h4 > span, h5 > span, h6 > span {
  position: relative;
}
h1 > span.split-parent, h2 > span.split-parent, h3 > span.split-parent, h4 > span.split-parent, h5 > span.split-parent, h6 > span.split-parent {
  overflow: hidden;
}
h1 > span.split-child, h2 > span.split-child, h3 > span.split-child, h4 > span.split-child, h5 > span.split-child, h6 > span.split-child {
  display: inline-block;
}
h1 + p, h1 + ul, h2 + p, h2 + ul, h3 + p, h3 + ul, h4 + p, h4 + ul, h5 + p, h5 + ul, h6 + p, h6 + ul {
  margin-top: 1.25em;
}

p, ul, li {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.9375rem;
  font-size: clamp(0.9375rem, 2.5vw, 1.5rem);
  margin-bottom: 1em;
}
p strong, ul strong, li strong {
  font-family: baskerville-urw, serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}
p a, ul a, li a {
  color: currentColor;
}
p a:hover, ul a:hover, li a:hover {
  color: #C6D552;
}

.userform,
form {
  margin: 1em auto;
  width: 90%;
  max-width: 800px;
}
.userform .field,
form .field {
  margin: 0 0 0.5em;
}
.userform .Actions,
form .Actions {
  margin: 1em 0;
}
.userform .left,
form .left {
  float: none;
}
.userform .btn-toolbar,
form .btn-toolbar {
  text-align: center;
}
.userform .btn-toolbar p,
form .btn-toolbar p {
  margin: 1em 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #A1A0A1;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #A1A0A1;
}

:-ms-input-placeholder {
  /* IE 10+ */
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #A1A0A1;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #A1A0A1;
}

.narrow {
  width: 400px !important;
  margin: 0 auto;
}

input[type=text],
input[type=email],
input[type=number],
input[type=password],
input[type=tel],
input[type=file],
select,
textarea {
  background: none;
  text-align: left;
  font-size: 1.03em;
  padding: 0.7em;
  width: 100%;
  letter-spacing: 0;
  border-radius: 0;
  box-sizing: border-box;
  border: 2px solid #A1A0A1;
  text-transform: none;
}
input[type=text].error,
input[type=email].error,
input[type=number].error,
input[type=password].error,
input[type=tel].error,
input[type=file].error,
select.error,
textarea.error {
  border-color: red;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=tel]:focus,
input[type=file]:focus,
select:focus,
textarea:focus {
  outline: 0;
}

textarea {
  height: 8em;
}

.custom-select {
  padding: 0 1.5em 0 0;
  margin: 0;
  border: 2px solid #A1A0A1;
  width: 100%;
  overflow: hidden;
  background: url("/images/chevron-down.svg") no-repeat 95% 50%;
  background-position-x: calc(100% - 0.7em);
  background-size: 0.7rem;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  text-transform: none;
}
.custom-select label.error {
  position: absolute;
}
.custom-select select {
  width: calc(100% + 1.5em);
  height: 100%;
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: relative;
  border: none;
  box-shadow: none;
  background-color: transparent;
  background-image: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select select:focus {
  outline: 0;
}

label {
  font-size: 1rem;
  float: none !important;
  margin-bottom: 1rem;
  line-height: 1rem;
  display: block;
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}
label:not(.error, .checkbox, .radio) {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
  color: #A1A0A1;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}

label.error,
span.error {
  color: #f00;
  font-style: italic;
  font-size: 0.8em;
  display: block !important;
  margin: 0.25em 0 0.5em;
  text-transform: none;
}

label.checkbox,
label.radio {
  cursor: pointer;
  padding-left: 2.5em;
  display: inline-block;
  position: relative;
  width: auto;
  text-align: left;
  line-height: 1.5em;
  letter-spacing: 0;
  margin: 1em 0;
}
label.checkbox a,
label.radio a {
  color: currentColor;
  text-decoration: underline;
}
label.checkbox a:hover,
label.radio a:hover {
  color: #C6D552;
}
label.checkbox span,
label.radio span {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  border: 2px solid #A1A0A1;
  border-radius: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
label.checkbox span:after,
label.radio span:after {
  position: absolute;
  top: 12.5%;
  left: 12.5%;
  width: 75%;
  height: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
  font-weight: bold;
  display: none;
  content: url("/images/close-icon.svg");
  color: black;
  line-height: 1em;
}
label.checkbox input,
label.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
label.checkbox input:disabled ~ span,
label.radio input:disabled ~ span {
  pointer-events: none;
  opacity: 0.6;
  background: #ccc;
}
label.checkbox input:checked ~ span,
label.radio input:checked ~ span {
  background: white;
  color: black;
}
label.checkbox input:checked ~ span:after,
label.radio input:checked ~ span:after {
  display: block;
  color: black;
}
label.checkbox:hover input ~ span,
label.radio:hover input ~ span {
  background: #ccc;
}
label.checkbox:hover input:not([disabled]):checked ~ span,
label.radio:hover input:not([disabled]):checked ~ span {
  background: #ccc;
  color: black;
}
@media (max-width: 639px) {
  label.checkbox,
  label.radio {
    font-size: 1em;
  }
}

@media (max-width: 640px) {
  label.checkbox {
    margin-bottom: 0;
  }
}

label.radio span {
  border-radius: 50%;
  overflow: hidden;
}
label.radio span:after {
  content: "";
  background: black;
  width: 0.88em;
  height: 0.88em;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 50%;
}
label.radio input:disabled ~ span {
  pointer-events: none;
  opacity: 0.6;
}
label.radio input:checked ~ span {
  background: white;
  color: black;
}
label.radio:hover input ~ span {
  background: white;
}
label.radio:hover input ~ span:after {
  color: black;
}
label.radio:hover input:not([disabled]):checked ~ span {
  background: #ccc;
  color: black;
}

.btn-toolbar.Actions, .actions p {
  text-align: center;
}

.actions p {
  margin-top: 1em;
}

.honeypot {
  display: none !important;
}

.field {
  position: relative;
  margin-bottom: 1.1rem;
}
.field.text .middleColumn, .field.text .custom-select, .field.email .middleColumn, .field.email .custom-select, .field.password .middleColumn, .field.password .custom-select, .field.textarea .middleColumn, .field.textarea .custom-select, .field.dropdown .middleColumn, .field.dropdown .custom-select, .field.phonenumber .middleColumn, .field.phonenumber .custom-select {
  display: flex;
  flex-direction: column-reverse;
}
.field.text label:not(.error), .field.email label:not(.error), .field.password label:not(.error), .field.textarea label:not(.error), .field.dropdown label:not(.error), .field.phonenumber label:not(.error) {
  transform: translate(1em, 3em);
  transition: all 0.3s;
}
.field.text .custom-select, .field.email .custom-select, .field.password .custom-select, .field.textarea .custom-select, .field.dropdown .custom-select, .field.phonenumber .custom-select {
  margin-top: 2em;
}
.field.text .custom-select select, .field.email .custom-select select, .field.password .custom-select select, .field.textarea .custom-select select, .field.dropdown .custom-select select, .field.phonenumber .custom-select select {
  position: relative;
  z-index: 1;
}
.field.text .custom-select label:not(.error), .field.email .custom-select label:not(.error), .field.password .custom-select label:not(.error), .field.textarea .custom-select label:not(.error), .field.dropdown .custom-select label:not(.error), .field.phonenumber .custom-select label:not(.error) {
  transform: translate(1.1em, 0);
  position: absolute;
}
.field.text .custom-select ~ label, .field.email .custom-select ~ label, .field.password .custom-select ~ label, .field.textarea .custom-select ~ label, .field.dropdown .custom-select ~ label, .field.phonenumber .custom-select ~ label {
  display: none;
}
.field.text input:focus ~ label, .field.text input:-webkit-autofill ~ label, .field.text textarea:focus ~ label, .field.text textarea:-webkit-autofill ~ label, .field.text input:not([value=""]):valid ~ label, .field.text textarea:not([value=""]):valid ~ label, .field.email input:focus ~ label, .field.email input:-webkit-autofill ~ label, .field.email textarea:focus ~ label, .field.email textarea:-webkit-autofill ~ label, .field.email input:not([value=""]):valid ~ label, .field.email textarea:not([value=""]):valid ~ label, .field.password input:focus ~ label, .field.password input:-webkit-autofill ~ label, .field.password textarea:focus ~ label, .field.password textarea:-webkit-autofill ~ label, .field.password input:not([value=""]):valid ~ label, .field.password textarea:not([value=""]):valid ~ label, .field.textarea input:focus ~ label, .field.textarea input:-webkit-autofill ~ label, .field.textarea textarea:focus ~ label, .field.textarea textarea:-webkit-autofill ~ label, .field.textarea input:not([value=""]):valid ~ label, .field.textarea textarea:not([value=""]):valid ~ label, .field.dropdown input:focus ~ label, .field.dropdown input:-webkit-autofill ~ label, .field.dropdown textarea:focus ~ label, .field.dropdown textarea:-webkit-autofill ~ label, .field.dropdown input:not([value=""]):valid ~ label, .field.dropdown textarea:not([value=""]):valid ~ label, .field.phonenumber input:focus ~ label, .field.phonenumber input:-webkit-autofill ~ label, .field.phonenumber textarea:focus ~ label, .field.phonenumber textarea:-webkit-autofill ~ label, .field.phonenumber input:not([value=""]):valid ~ label, .field.phonenumber textarea:not([value=""]):valid ~ label {
  transform: none;
}
.field.text select:focus ~ label, .field.text select:-webkit-autofill ~ label, .field.text .custom-select select:not([value=""]):valid ~ label, .field.email select:focus ~ label, .field.email select:-webkit-autofill ~ label, .field.email .custom-select select:not([value=""]):valid ~ label, .field.password select:focus ~ label, .field.password select:-webkit-autofill ~ label, .field.password .custom-select select:not([value=""]):valid ~ label, .field.textarea select:focus ~ label, .field.textarea select:-webkit-autofill ~ label, .field.textarea .custom-select select:not([value=""]):valid ~ label, .field.dropdown select:focus ~ label, .field.dropdown select:-webkit-autofill ~ label, .field.dropdown .custom-select select:not([value=""]):valid ~ label, .field.phonenumber select:focus ~ label, .field.phonenumber select:-webkit-autofill ~ label, .field.phonenumber .custom-select select:not([value=""]):valid ~ label {
  transform: translate(0, -3.1em) !important;
}

button, a {
  padding: none;
  display: inline-block;
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
}

.btn {
  border: 1px solid;
  color: black;
  display: inline-block;
  line-height: 0;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  transition: background 0.3s;
}
.btn .ir {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
.btn button, .btn a {
  color: black;
  text-decoration: none;
  padding: 0.875em;
  min-width: 7.375em;
  line-height: 1;
  overflow: hidden;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
.btn:hover {
  background: #C6D552;
}

input[type=submit] {
  background: none;
  border: 1px solid;
  color: black;
  display: inline-block;
  line-height: 1;
  text-align: center;
  overflow: hidden;
  cursor: pointer;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.01em;
  padding: 0.875em;
  min-width: 7.375em;
  text-decoration: none;
  text-transform: uppercase;
  transition: background 0.3s;
}
input[type=submit]:hover {
  background: #C6D552;
}

.btn.inline {
  color: black;
  text-decoration: none;
  padding: 0.875em;
  min-width: 7.375em;
  line-height: 1;
  overflow: hidden;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
  min-height: 2.75em;
}

.logolink {
  width: 100%;
}

body {
  overflow-x: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100dvh;
  height: 100dvh;
  background: white;
  padding-top: 75px;
  color: #434243;
}
body.is-transitioning {
  pointer-events: none;
  cursor: progress;
}
body header.main {
  order: 0;
}
body main {
  order: 1;
  flex-grow: 1;
}
body footer.main {
  order: 2;
}
@media (min-width: 960px) {
  body {
    padding-top: 200px;
  }
}

.container {
  max-width: 1240px;
  width: 85%;
  margin: 0 auto;
  position: relative;
}

.glide__slides {
  overflow: visible;
}

.hero {
  width: 100%;
  display: block;
}
.hero img, .hero video {
  width: 100%;
  height: auto;
  display: block;
}
.hero.covered {
  position: relative;
  height: 62svh;
  height: 62vh;
  max-height: 400px;
  object-fit: cover;
  object-position: center top;
}
@media (min-width: 768px) {
  .hero.covered {
    height: 718px;
    max-height: 100vh;
  }
}
@media (min-width: 768px) {
  .hero {
    min-height: 1vh;
  }
}

.introcopy {
  padding: 2em 0 4em;
  width: 81.25%;
  max-width: 870px;
  margin: 0 auto;
  text-align: center;
}
.introcopy p {
  margin-bottom: 0;
  margin-top: 1em;
}
.introcopy.square {
  padding: 3em 0;
}
@media (min-width: 768px) {
  .introcopy.square {
    padding: 6em 0;
  }
}
@media (min-width: 768px) {
  .introcopy {
    padding: 6em 0 8em;
  }
}

.split-content {
  margin: 4em 0;
  border-bottom: 1px solid #A1A0A1;
}
.split-content .container {
  max-width: 1380px;
  text-align: center;
  width: 100%;
}
@media (min-width: 768px) {
  .split-content .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.split-content img, .split-content .video-container, .split-content video, .split-content a.image {
  width: 100%;
  max-width: 685px;
  height: auto;
  margin: 0 auto;
}
.split-content img video, .split-content img iframe, .split-content .video-container video, .split-content .video-container iframe, .split-content video video, .split-content video iframe, .split-content a.image video, .split-content a.image iframe {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}
.split-content img img, .split-content .video-container img, .split-content video img, .split-content a.image img {
  width: 100%;
  display: block;
}
@media (min-width: 768px) {
  .split-content img, .split-content .video-container, .split-content video, .split-content a.image {
    width: 50%;
    margin: 0;
  }
}
.split-content .copy {
  padding: 2em 0;
  width: 85%;
  margin: 0 auto;
}
.split-content .copy p {
  font-size: clamp(0.8125rem, 2vw, 1.25rem);
}
@media (min-width: 768px) {
  .split-content .copy {
    margin: 0;
    text-align: left;
    padding: 0 0 0 12%;
    width: 46%;
  }
}
@media (min-width: 768px) {
  .split-content {
    margin: 6em 0 0;
    border-top: 1px solid #A1A0A1;
  }
}

.announcement {
  padding: 4em 0;
  border-bottom: 1px solid #A1A0A1;
  position: relative;
}
.announcement img {
  width: 100%;
  height: auto;
  display: block;
}
@media (min-width: 768px) {
  .announcement img {
    aspect-ratio: 1514/651;
    object-fit: cover;
  }
}
.announcement .copy {
  width: 82%;
  margin: 0 auto;
  padding-top: 2em;
  text-align: center;
}
@media (min-width: 768px) {
  .announcement .copy {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-align: left;
  }
  .announcement .copy .wrap {
    width: 90%;
    max-width: 1280px;
    margin: 0 auto;
  }
  .announcement .copy .wrap .innerwrap {
    width: 100%;
    max-width: 580px;
  }
  .announcement .copy h3, .announcement .copy p {
    text-shadow: 5px 5px 20px #000000;
  }
}
@media (min-width: 768px) {
  .announcement {
    padding: 8em 0;
    border-bottom: 0;
    aspect-ratio: 1514/651;
  }
}
.Macanudo\\Pages\\ProductsPage .announcement {
  padding-top: 0 !important;
}

dialog {
  padding: 0;
  position: fixed;
  width: 100%;
  max-width: 100%;
  margin: 0;
  border: 0;
  top: 0;
  left: 0;
  background-size: cover;
  min-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
dialog .container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0;
}
dialog p {
  text-transform: uppercase;
  text-align: center;
  max-width: 700px;
  margin: 2em auto 0;
}
dialog p a {
  text-decoration: underline;
}
dialog p.legal {
  font-size: 0.85em;
  text-transform: none;
  font-weight: 400;
}
dialog .Actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0 3rem;
  width: 75%;
  max-width: 600px;
}
dialog .Actions button {
  width: 45%;
}
dialog .close {
  text-decoration: none;
  position: absolute;
  top: 2em;
  right: 2em;
  line-height: 1;
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  justify-content: center;
  letter-spacing: 0;
  line-height: 1;
}

#agegate-dialog {
  display: flex;
}
#agegate-dialog:not([open]) {
  display: none !important;
}
#agegate-dialog .container {
  min-height: 100vh;
  margin: auto;
  position: relative;
  width: 100%;
  border-bottom: 2px solid #A1A0A1;
  max-width: 1300px;
  justify-content: flex-end;
}
@media (min-width: 769px) {
  #agegate-dialog .container {
    padding: 75px 75px 0;
    width: 95%;
  }
}
@media screen and (min-width: 769px) and (min-height: 800px) {
  #agegate-dialog .container {
    min-height: 800px;
  }
}
#agegate-dialog .Actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#agegate-dialog .Actions .btn {
  width: 45%;
}
#agegate-dialog .Actions .btn button {
  width: 100%;
  min-width: 100%;
}
#agegate-dialog form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 640px;
  text-align: center;
}

header {
  height: 75px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: white;
  display: flex;
  transition: box-shadow 0.3s;
  border-bottom: 2px solid #A1A0A1;
}
header.scrolled {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
header nav {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 960px) {
  header nav {
    max-width: 1440px;
    margin: 0 auto;
  }
}
header .logo {
  flex-grow: 1;
}
header .logo img {
  width: 70px;
  height: auto;
  display: block;
}
@media (min-width: 960px) {
  header .logo img {
    width: 100%;
  }
}
@media (min-width: 960px) {
  header .logo {
    width: 15%;
    max-width: 212px;
    flex-grow: initial;
  }
}
header ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
  line-height: 1;
  font-size: 1rem;
  position: absolute;
  top: 75px;
  width: 100%;
  left: 100%;
  transition: transform 0.3s;
  min-height: calc(100vh - 75px);
  background: white;
  padding-top: 2em;
}
@media (min-width: 960px) {
  header ul {
    display: flex;
    justify-content: space-evenly;
    padding: 1rem;
    border-top: 1px solid #837754;
    border-bottom: 1px solid #837754;
    position: relative;
    transform: none;
    left: 0;
    top: 0;
    min-height: 1px;
    width: calc(85% - 2rem);
    margin-left: 2rem;
  }
}
header ul li {
  line-height: 1;
  font-size: 1rem;
}
@media (min-width: 960px) {
  header ul li {
    margin-bottom: 0;
    font-size: 0.8rem;
  }
}
@media (min-width: 1100px) {
  header ul li {
    font-size: 1rem;
  }
}
header ul a {
  color: #434243;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 1;
}
header nav.active ul.secondary {
  transform: translateX(-100%);
}
header .hamburger {
  width: auto;
  min-width: 1em;
  display: flex;
  z-index: 1;
}
header .hamburger .hamburger-inner, header .hamburger .hamburger-inner::before, header .hamburger .hamburger-inner::after {
  background: #837754;
  height: 2px;
}
@media (min-width: 960px) {
  header .hamburger {
    display: none;
  }
}
@media (min-width: 960px) {
  header {
    height: 200px;
  }
}

footer.main {
  z-index: 2;
  padding: 2em;
  text-align: center;
  border-top: 2px solid #A1A0A1;
}
footer.main p {
  margin-bottom: 0;
  font-size: 1rem;
}
footer.main nav {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 800px) {
  footer.main nav {
    justify-content: flex-end;
  }
}
footer.main nav a {
  color: black;
  text-decoration: none;
}
footer.main nav a:hover {
  color: #C6D552;
}
footer.main nav ul {
  list-style: none;
  margin: 1em 0;
  padding: 0 1em;
  font-size: 1rem;
}
@media (min-width: 800px) {
  footer.main nav ul {
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  footer.main nav ul li {
    margin: 0 1em;
    font-size: 1rem;
  }
}
footer.main nav ul li.social {
  display: flex;
  align-items: center;
}
footer.main nav ul svg {
  fill: currentColor;
  width: auto !important;
  height: 26px !important;
  margin: 1em;
}
@media (min-width: 800px) {
  footer.main {
    display: flex;
    align-items: center;
  }
}

.crm-signup img {
  max-width: 58%;
  padding-left: 20px;
}
.crm-signup h4 {
  font-size: 3em;
  padding-bottom: 1em;
}
.crm-signup .fields {
  display: flex;
}
.crm-signup .actions {
  padding: 32px 0 0;
  height: 60px;
}
.crm-signup .action {
  height: 51.77px;
  background-color: #434243;
  color: white !important;
  border: 0px;
}
.crm-signup #RegistrationForm_RegistrationForm {
  margin: 0px;
}
@media (max-width: 767px) {
  .crm-signup .fields {
    justify-content: center;
  }
}

.element {
  padding: 4em 0;
  scroll-margin-top: calc(80px - 2em);
}
.element:not(.noborders) {
  border-top: 1px solid #A1A0A1;
}
.element .element-title {
  padding: 0;
  margin-bottom: 0.25em;
}
@media (min-width: 768px) {
  .element {
    padding: 6em 0;
  }
}
@media (min-width: 960px) {
  .element {
    scroll-margin-top: calc(200px - 4em);
  }
}

.element + .element.macanudo__blocks__promotionblock, .element.macanudo__blocks__promotionblock + .element, .element.macanudo__blocks__splitcontentblock + .element, .element + .macanudo__blocks__splitcontentblock {
  border-top: 0;
  padding-top: 0;
}

.element.dnadesign__elemental__models__elementcontent.noborders {
  padding: 6em 0 3em;
}

.no-pad {
  padding: 0 !important;
}

.no-pad-top {
  padding-top: 0 !important;
}

.no-pad-bottom {
  padding-bottom: 0 !important;
}

.element.macanudo__blocks__imageblock:first-child {
  padding: 0px !important;
}

.element.macanudo__blocks__carouselblock {
  max-width: 100%;
}
.element.macanudo__blocks__carouselblock .element-title {
  padding: 1rem;
}

.element-carousel-container .glide__slides {
  align-items: center;
}
.element-carousel-container .glide__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
.element-carousel-container img, .element-carousel-container video {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.element-carousel-container .glide__track {
  position: relative;
  width: 100%;
  height: 100%;
}
.element-carousel-container .glide__arrow {
  display: none;
}
.element-carousel-container .glide__arrow:focus {
  outline: 0;
}
.element-carousel-container .glide__arrow--left {
  position: absolute;
  top: 50%;
  left: 12%;
  transform: rotate(90deg);
  background: none;
  border: none;
}
.element-carousel-container .glide__arrow--right {
  position: absolute;
  top: 50%;
  right: 12%;
  transform: rotate(-90deg);
  background: none;
  border: none;
}
.element-carousel-container .glide__bullets {
  justify-content: center;
  bottom: 2em;
  display: flex;
  list-style: none;
}
.element-carousel-container .glide__bullet {
  background-color: #D9D9D9;
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.element-carousel-container .glide__bullet:focus {
  outline: none;
}
.element-carousel-container .glide__bullet:hover, .element-carousel-container .glide__bullet:focus {
  border: 2px solid black;
  background-color: #D9D9D9;
}
.element-carousel-container .glide__bullet--active {
  background-color: black;
}
.element-carousel-container .non-carousel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 100%;
}
.element-carousel-container .non-carousel img, .element-carousel-container .non-carousel video {
  width: 100%;
  max-width: 900px;
}
@media (min-width: 769px) {
  .element-carousel-container .non-carousel img, .element-carousel-container .non-carousel video {
    width: 50%;
  }
}
.element-carousel-container .non-carousel img + img, .element-carousel-container .non-carousel video + video, .element-carousel-container .non-carousel img + video, .element-carousel-container .non-carousel video + img {
  margin: 50px 0 0 0;
}
@media (min-width: 769px) {
  .element-carousel-container .non-carousel img + img, .element-carousel-container .non-carousel video + video, .element-carousel-container .non-carousel img + video, .element-carousel-container .non-carousel video + img {
    margin: 0;
  }
}

.macanudo__blocks__imageblock .image-container {
  width: 100%;
  margin: 0 auto;
}
.macanudo__blocks__imageblock .image-container img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.macanudo__blocks__sidebysideblock .assets {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.macanudo__blocks__sidebysideblock .assets .side {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
.macanudo__blocks__sidebysideblock .assets .side img, .macanudo__blocks__sidebysideblock .assets .side video {
  object-fit: cover;
  width: 100%;
  display: block;
  height: auto;
  max-width: initial;
}
.macanudo__blocks__sidebysideblock .assets .side.content {
  padding: 1em;
  margin: 0;
}
.macanudo__blocks__sidebysideblock .assets .side.content h1, .macanudo__blocks__sidebysideblock .assets .side.content h2, .macanudo__blocks__sidebysideblock .assets .side.content h3, .macanudo__blocks__sidebysideblock .assets .side.content h4, .macanudo__blocks__sidebysideblock .assets .side.content h5, .macanudo__blocks__sidebysideblock .assets .side.content h6, .macanudo__blocks__sidebysideblock .assets .side.content p {
  width: 100%;
  margin: 0;
}
.macanudo__blocks__sidebysideblock .assets .side.content p {
  margin: 1em 0;
}
.macanudo__blocks__sidebysideblock .assets .side.content .btn {
  display: inline-block;
}
@media (min-width: 769px) {
  .macanudo__blocks__sidebysideblock .assets .side.content {
    padding: 1em 2.5em;
  }
}
@media (min-width: 769px) {
  .macanudo__blocks__sidebysideblock .assets .side {
    width: 50%;
  }
}
@media (min-width: 769px) {
  .macanudo__blocks__sidebysideblock .assets.flipped {
    flex-direction: row-reverse;
  }
  .macanudo__blocks__sidebysideblock .assets.flipped .side.content {
    text-align: right;
    list-style-position: inside;
  }
}
@media (min-width: 769px) {
  .macanudo__blocks__sidebysideblock .assets {
    align-items: center;
  }
}

.macanudo__blocks__videoblock, .macanudo__blocks__embeddedvideoblock {
  padding: 0px !important;
}
.macanudo__blocks__videoblock .video-container, .macanudo__blocks__embeddedvideoblock .video-container {
  margin: 0 auto;
  position: relative;
  width: 100%;
  position: relative;
}
.macanudo__blocks__videoblock .video-container:before, .macanudo__blocks__embeddedvideoblock .video-container:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.macanudo__blocks__videoblock .video-container video, .macanudo__blocks__videoblock .video-container iframe, .macanudo__blocks__videoblock .video-container embed, .macanudo__blocks__videoblock .video-container object, .macanudo__blocks__videoblock .video-container .plyr, .macanudo__blocks__embeddedvideoblock .video-container video, .macanudo__blocks__embeddedvideoblock .video-container iframe, .macanudo__blocks__embeddedvideoblock .video-container embed, .macanudo__blocks__embeddedvideoblock .video-container object, .macanudo__blocks__embeddedvideoblock .video-container .plyr {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.macanudo__blocks__videoblock video, .macanudo__blocks__embeddedvideoblock video {
  width: 100%;
}

.embed {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.embed:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.embed iframe, .embed video, .embed img {
  width: 100%;
  height: 100%;
  max-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.macanudo__blocks__embeddedvideoblock .video-container {
  position: relative;
}
.macanudo__blocks__embeddedvideoblock .video-container:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}

.plyr {
  --plyr-color-main:$green;
}
.plyr.plyr--stopped .plyr__controls {
  display: none;
}
.plyr .plyr__control--overlaid {
  background: none;
  border: 3px solid white;
  padding: 2em;
  box-shadow: 0px 0px 14px 0px rgba(21, 21, 49, 0.66);
}
.plyr .plyr__control--overlaid svg {
  width: 2em;
  height: 2em;
}
.plyr .plyr__control--overlaid:hover {
  background: #C6D552;
}
.plyr.plyr--full-ui input[type=range] {
  color: #C6D552;
}
.plyr.plyr--video .plyr__control.plyr__tab-focus, .plyr.plyr--video .plyr__control:hover, .plyr.plyr--video .plyr__control[aria-expanded=true] {
  background: #C6D552;
}
.plyr .plyr__poster {
  background-size: cover;
}

.element.macanudo__blocks__tabularcontentblock.noborders {
  padding-top: 0px !important;
  padding: 0px !important;
}

.tabular-content {
  display: grid;
  grid-template-columns: repeat(3, 250px [col-start]);
  grid-template-rows: [row1-start] 60% [row1-end row2-start] 60% [row2-end];
  justify-content: center;
}
.tabular-content .section {
  padding: 0 10px;
}
.tabular-content .section h2 {
  text-align: center;
  padding: 0 0 5px;
}
.tabular-content .section h2.bask {
  font-size: 1.15em !important;
}
.tabular-content .section hr {
  width: 40%;
  margin: 0 auto 20px;
  color: black !important;
}
.tabular-content .section p {
  padding: 0px 0 0;
  font-size: 0.9375rem !important;
}
.tabular-content .section img {
  width: 30px;
  height: auto;
}
@media (max-width: 767px) {
  .tabular-content .section {
    margin-bottom: 50px;
  }
}
@media (max-width: 767px) {
  .tabular-content {
    display: block !important;
  }
}

@media (max-width: 639px) {
  .Page.home .macanudo__blocks__imageblock img {
    aspect-ratio: 1;
    object-fit: cover;
    height: auto;
  }
}

.Macanudo\\Pages\\RegistrationPage .app .container, .Macanudo\\Pages\\ContactPage .app .container {
  max-width: 970px;
  padding: 4em 0;
}
.Macanudo\\Pages\\RegistrationPage .app .container form .inputs, .Macanudo\\Pages\\ContactPage .app .container form .inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Macanudo\\Pages\\RegistrationPage .app .container form .inputs .field-wrap, .Macanudo\\Pages\\ContactPage .app .container form .inputs .field-wrap {
  width: 100%;
}
.Macanudo\\Pages\\RegistrationPage .app .container form .inputs .field-wrap textarea, .Macanudo\\Pages\\ContactPage .app .container form .inputs .field-wrap textarea {
  margin-bottom: 2em;
}
@media (min-width: 768px) {
  .Macanudo\\Pages\\RegistrationPage .app .container form .inputs .field-wrap, .Macanudo\\Pages\\ContactPage .app .container form .inputs .field-wrap {
    width: 48%;
  }
  .Macanudo\\Pages\\RegistrationPage .app .container form .inputs .field-wrap.Zip, .Macanudo\\Pages\\ContactPage .app .container form .inputs .field-wrap.Zip {
    width: 30%;
  }
  .Macanudo\\Pages\\RegistrationPage .app .container form .inputs .field-wrap.SurveyOptIn, .Macanudo\\Pages\\RegistrationPage .app .container form .inputs .field-wrap.Message, .Macanudo\\Pages\\ContactPage .app .container form .inputs .field-wrap.SurveyOptIn, .Macanudo\\Pages\\ContactPage .app .container form .inputs .field-wrap.Message {
    width: 100%;
  }
}
.Macanudo\\Pages\\RegistrationPage .app .container form .actions, .Macanudo\\Pages\\ContactPage .app .container form .actions {
  text-align: center;
}
.Macanudo\\Pages\\RegistrationPage.thanks, .Macanudo\\Pages\\ContactPage.thanks {
  text-align: center;
}
.Macanudo\\Pages\\RegistrationPage.thanks svg, .Macanudo\\Pages\\ContactPage.thanks svg {
  display: block;
  width: 50%;
  max-width: 150px;
  height: auto;
  margin: 2em auto;
}
.Macanudo\\Pages\\RegistrationPage.thanks svg .cigar-world-logo-mark-2, .Macanudo\\Pages\\ContactPage.thanks svg .cigar-world-logo-mark-2 {
  fill: #D94E00;
}

.Macanudo\\Pages\\AboutPage .features {
  text-align: center;
}
.Macanudo\\Pages\\AboutPage .features .feature {
  border-top: 1px solid #A1A0A1;
}
.Macanudo\\Pages\\AboutPage .features .feature img {
  width: 66.875%;
  height: auto;
  margin-top: -2em;
  max-width: 534px;
}
@media (min-width: 768px) {
  .Macanudo\\Pages\\AboutPage .features .feature img {
    order: 1;
    width: 43%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
.Macanudo\\Pages\\AboutPage .features .feature .copy {
  padding: 2em 0 4em;
  width: 80%;
  max-width: 520px;
  margin: 0 auto;
}
.Macanudo\\Pages\\AboutPage .features .feature .copy h2 {
  font-size: clamp(1.25rem, 3vw, 2rem);
}
@media (min-width: 768px) {
  .Macanudo\\Pages\\AboutPage .features .feature .copy {
    margin: 0;
    padding: 6em 0 6em 12%;
    border-left: 1px solid;
    order: 0;
    width: 54%;
  }
}
@media (min-width: 768px) {
  .Macanudo\\Pages\\AboutPage .features .feature .wrap {
    width: 90%;
    max-width: 1234px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: left;
  }
}
.Macanudo\\Pages\\AboutPage .features .feature:last-child {
  border-bottom: 1px solid #A1A0A1;
}
@media (min-width: 768px) {
  .Macanudo\\Pages\\AboutPage .features .feature:last-child {
    margin-bottom: 8em;
  }
  .Macanudo\\Pages\\AboutPage .features .feature:last-child .wrap {
    justify-content: flex-end;
  }
  .Macanudo\\Pages\\AboutPage .features .feature:last-child img {
    left: 0;
    top: 55%;
  }
  .Macanudo\\Pages\\AboutPage .features .feature:last-child .copy {
    max-width: 580px;
    padding-top: 4em;
    padding-bottom: 4em;
  }
}
@media (min-width: 768px) {
  .Macanudo\\Pages\\AboutPage .features .feature {
    border-top: 1px solid #A1A0A1;
    border-bottom: 1px solid #A1A0A1;
    margin: 8em 0 16em;
  }
}

.Macanudo\\Pages\\ExperiencePage .introcopy {
  max-width: 1355px;
}
.Macanudo\\Pages\\ExperiencePage .introcopy p {
  max-width: 698px;
  margin: 1em auto 0;
}

.Macanudo\\Pages\\EventsPage .actions {
  text-align: center;
  padding-bottom: 2em;
}
.Macanudo\\Pages\\EventsPage .btn {
  box-sizing: border-box;
  width: 200px;
  margin: 0px 20px;
}
.Macanudo\\Pages\\EventsPage .container {
  justify-content: center;
  margin: 0 auto;
}
.Macanudo\\Pages\\EventsPage .event img {
  max-width: 300px;
  padding: 50px 0;
}
@media (max-width: 767px) {
  .Macanudo\\Pages\\EventsPage .event img {
    max-width: 200px;
    padding: 20px 0;
  }
}
.Macanudo\\Pages\\EventsPage .image-container {
  width: 100%;
  margin: 0 auto;
}
.Macanudo\\Pages\\EventsPage .image-container img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.Macanudo\\Pages\\EventsPage .copy h3.location.bask {
  font-size: 1em;
}
.Macanudo\\Pages\\EventsPage .copy hr {
  max-width: 150px;
  margin-bottom: 40px;
}
@media (min-width: 769px) {
  .Macanudo\\Pages\\EventsPage .copy hr {
    margin-left: 0px;
  }
}
.Macanudo\\Pages\\EventsPage .copy h2 {
  font-size: 2em;
}
.Macanudo\\Pages\\EventsPage .copy p {
  font-size: clamp(0.9375rem, 2.5vw, 1.5rem);
  margin-top: 10px;
}

.private-lockers .content h1 {
  font-size: 4em !important;
}
.private-lockers .content hr {
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 769px) {
  .private-lockers .content hr {
    margin-left: 0px !important;
  }
}
.private-lockers .content .btn {
  width: 265px;
  height: auto;
}