.crm-signup{
    
            img{
                max-width: 58%;
                padding-left:20px;
            }
        
            h4{
                font-size: 3em;
                padding-bottom:1em;
            }

            .fields{
                display: flex;
            }

            .actions{
                padding: 32px 0 0 ;
                height: 60px;
            }

            .action{
                height: 51.77px;
                background-color: #434243;
                color: white !important;
                border: 0px;
            }

            #RegistrationForm_RegistrationForm{
                margin: 0px;
            }
        @include media("<tablet"){
            .fields{
                justify-content: center;
            }
        }
}