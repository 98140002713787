.macanudo__blocks__sidebysideblock{
    .assets{
        display: flex;
        flex-wrap:wrap;
        width: 100%;

        .side{
            width: 100%;
            position: relative;
            overflow-x:hidden;

            img, video{
                object-fit: cover;
                width: 100%;
                display: block;
                height: auto;
                max-width: initial;
            }

            &.content{
                padding:1em;
                margin:0;
                h1,h2,h3,h4,h5, h6, p{
                    width:100%;
                    margin:0;
                }
                p{
                    margin:1em 0;
                }
                .btn{
                    display: inline-block;
                }
                @include media('>tablet'){
                    padding:1em 2.5em;
                }
            }

            @include media('>tablet'){
                width: 50%;
            }
        }

        &.flipped{
            @include media('>tablet'){
                flex-direction: row-reverse;
                .side.content{
                    text-align: right;
                    list-style-position: inside;
                }
            }
        }

        @include media('>tablet'){
            align-items: center;
        }
    }
}
