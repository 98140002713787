@function em($pixels, $context: 16) {
  @return #{$pixels/$context}em;
}

@function lh($font-size, $leading) {
   @return #{$leading / $font-size}em;
}

@function ls($tracking) {
   @return #{$tracking / 1000}em;
}

//bold=700, semibold=600, light=300, thin=100

@mixin proximanova($weight:400, $style:normal){
    font-family: proxima-nova, sans-serif;
    font-weight:$weight;
    font-style:$style;
}

@mixin baskerville($weight:700, $style:normal){
    font-family: baskerville-urw, serif;
    font-weight: $weight;
    font-style: $style;
    text-transform: uppercase;
}
