.element.macanudo__blocks__carouselblock{
    max-width: 100%;
    .element-title{
        padding:1rem;
    }
}
.element-carousel-container{
    .glide__slides{
        align-items:center;
    }
    .glide__slide{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img, video{
        width: 100%;
        max-width: 100%;
        height: auto;
        // box-shadow: 0 0 10px 5px #e1dfe3;
    }

    .glide__track{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .glide__arrow{
        display:none;
        // width: 50px;
        // fill:$green;
        // stroke:white;
        // &:hover{
        //     fill:$green;
        // }
    }
    .glide__arrow:focus{
        outline:0;
    }
    .glide__arrow--left{
        position: absolute;
        top: 50%;
        left: 12%;
        transform: rotate(90deg);
        background:none;
        border: none;

    }
    .glide__arrow--right{
        position: absolute;
        top: 50%;
        right: 12%;
        transform: rotate(-90deg);
        background:none;
        border: none;
    }

    // .glide__bullets{
    //     display:flex;
    //     justify-content: center;
    // }

    // .glide_bullet--active{
    //     background-color:#ff0000 !important;
    // }
    
    // .glide__bullet{
    //     background-color:#000000;
    //     margin: 0 5px;
    // }

    .glide__bullets {
        justify-content: center;
        bottom: 2em;
        display: flex;
        list-style: none;
        
      }
      .glide__bullet {
        background-color: #D9D9D9;
        width: 9px;
        height: 9px;
        padding: 0;
        border-radius: 50%;
        border: 2px solid transparent;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        line-height: 0;
        box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
        margin: 0 0.25em;
      }
      .glide__bullet:focus {
        outline: none;
      }
      .glide__bullet:hover, .glide__bullet:focus {
        border: 2px solid black;
        background-color: #D9D9D9;
      }
      .glide__bullet--active {
        background-color: black;
      }

    .non-carousel{
        display: flex;
        align-items:center;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;

        img, video{
            width:100%;
            max-width: 900px;
            @include media(">tablet"){
                width: 50%;
            }
        }

        img+img, video+video, img+video, video+img{
            margin:50px 0 0 0;
            @include media(">tablet"){
                margin: 0;
            }
        }
    }
}
